.bs-card:hover {
    background-color: #FF908B;
}

.app-group {
    text-transform: capitalize;
}

.grid-container {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    /* Two rows with equal height */
    grid-template-columns: repeat(3, 1fr);
    /* Three columns with equal width */
    gap: 10px;
    /* Adjust the gap between grid items */
    font-family: 'Sen', sans-serif !important;
    background: rgba(200, 200, 200, 0.5);
    height: 180px;
    /* Set the height to 180px */
    margin: 10px;
    border-radius: 10px;
    align-items: center;
    overflow: hidden;
    /* Ensure apps are contained within the group */
    text-transform: capitalize !important;
}

.grid-item {
    text-align: center;
  }