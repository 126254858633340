/* GridItem.css */

.grid-item-name {
  font-family: 'Iosevka Web', 'Sen', sans-serif;
  font-size: 10px;
}

.app-icon-container {
    display: block;
    text-align: center;
  }
  
  .app-icon {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
  }
  
  .app-name {
    font-size: 10px;
    max-width: 50px;
    white-space: pre-wrap;
    text-align: center;
    display: flex;
    align-items: center;
  }
  