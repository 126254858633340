/* App.css */
@import url('https://fonts.googleapis.com/css?family=Ek+Mukta:300,400,600');
@import url('https://fonts.googleapis.com/css2?family=Sen:wght@500&display=swap');
@import url('https://pvinis.github.io/iosevka-webfont/3.4.1/iosevka.css');

/* Set the body and html elements to full height to ensure the Container can extend */
body, html {
  height: 100%;
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
}

.App {
  font-family: 'Iosevka Web', 'Sen', sans-serif;
  font-weight: 600;
  text-align: center;
  color: #FFFFFF;
  background-color: #1D1F20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  overflow: auto; /* Ensure scrolling doesn't extend background color */
}

.AppTitle {
  position: relative;
  top: 2rem;
}

.container-fluid {
  min-height: 100vh;
  padding: 0; /* Remove default padding */
}

/* Rest of your CSS remains unchanged */

ul {
    padding-left: 0rem !important;
}

h1 {
  margin-top: 10vh;
  font-size: 2.5rem;
  max-width: 500px;
  line-height: 1.5;
  text-transform: capitalize;
  font-weight: 800;
  color: white;
}

h1 span {
  color: #FF908B;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 18rem;
  margin-top: 0;
  top: 4rem;
}

.chosen-value {
  text-transform: capitalize;
  font-weight: 600;
  height: 4rem;
  width: 100%;
  font-size: 1.1rem;
  padding: 1rem;
  background-color: #FAFCFD;
  border: 3px solid transparent;
  transition: .3s ease-in-out;
  border-radius: 0.375rem;
}

.chosen-value::placeholder {
  color: #333;
}

.chosen-value:hover {
  background-color: #FF908B;
  cursor: pointer;
}

.chosen-value:hover::placeholder {
  color: #333;
}

.chosen-value:focus,
.chosen-value.open {
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
  outline: 0;
  background-color: #FF908B;
  color: #000;
  width: 100%;
}

.chosen-value:focus::placeholder,
.chosen-value.open::placeholder {
  color: #000;
}

.value-list {
  list-style: none;
  margin-top: 4rem;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  max-height: 0;
  transition: .3s ease-in-out;
  width: 100%; /* Set the same width as the input */
}

.value-list.open {
    max-height: 100%; /* Set an appropriate max-height value to keep the dropdown open */
    overflow: auto;
  }
  

.value-list li {
  position: relative;
  height: 4rem;
  width: 100%;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color .3s;
  opacity: 1;
  padding-left: 0; /* Remove left padding */
  line-height: 4rem; /* Set line-height to match the height */
  padding: 1rem;
  border-radius: 0.375rem;
}

.value-list li:hover {
  background-color: #FF908B;
  width: 100% !important; /* Extend background color to full width */
}

.value-list li.closed {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  opacity: 0;
}
